<template>
<div class="container main-body">
    <div class="row grid">
       <div class="col-sm-6 col-md-4 col-12 templatemo-item-col all soon" v-for="project in projects" :key="project.id">
           <Video :project="project" />
       </div>
   </div>
</div>   
</template>

<script>
import Video from "../components/Video.vue";
//import Videx from "../components/Videx.vue";

export default{
    
    name:"Videos",
    data(){
        return{

        }
    },
    components:{
        Video,
       
    },
    computed:{
        projects(){
            return this.$store.state.projects;
        }
        
    }
    
}
</script>

<style scoped>

</style>
<template>
     <!-- Masthead-->
        <header class="masthead" v-bind:style="{ 'background-image': 'url(' + this.image + ')' }">
            <div class=" px-4 px-lg-5 h-100">
                <div class="row gx-4 gx-lg-5 h-100 align-items-center justify-content-center text-center">
                    <div class="col-lg-8 align-self-end">
                        <h1 class="text-white font-weight-bold">Maximize your Productivity by Leaning with Us</h1>
                        <hr class="divider" />
                    </div>
                    <div class="col-lg-8 align-self-baseline">
                        <p class="text-white-75 mb-5">Improve your Knowledge and skill every day by Leaning Top Courses form Top Coachies</p>
                        <router-link class="btn btn-secondary btn-xl" to="/audios">View Courses</router-link>
                    </div>
                </div>
            </div>   
        </header>

        <div class="course-info ">
            <h2>Courses and Study Material</h2>
            <p>Enjoy Learning with maximum flexibility, high quality content superb presentation multiple format prepaled by Expert </p>
        </div>

        <div class="container ">
        <div class="row">
            <div class="col-sm-6 col-md-4">
              <div class="card1">
                <i class="fa fa-music course-icons"></i>
                <h5>Audio course</h5>
                <p>Easly leaning by listening experts, choose your topic to feed your brain</p>
              </div>
            </div>

            <div class="col-sm-6 col-md-4 w-100">
              <div class="card1">
                  <i class="fa fa-book course-icons"></i>
                <h5>Books</h5>
                <p>Aquire amazing and usefully secret's hidden in books, and find your way</p>
              </div>
            </div>

            <div class="col-sm-6 col-md-4">
              <div class="card1">
                   <i class="fa fa-film course-icons"></i>
                <h5>Video course</h5>
                <p>Watch, Listen, Enjoy and Learn through videos presented by experts</p>
              </div>
            </div>
            
        </div>
        </div>

         <div class="course-info container">
            <h3>Most Popular Books</h3>
            <div class="row mt-3">
              <div class="col-md-2 col-sm-6 col-6" v-for="project in projects" :key="project.id">
                  <Book :book="project"/>
              </div>
            </div>
            <router-link class="btn btn-secondary btn-xl" to="/books">All Books</router-link>
        </div>

        <div class="course-info container">
            <h3>Popular Audio Courses</h3>
            <div class="row mt-3">
              <div class="col-md-4 col-sm-6" v-for="project in projectz" :key="project.id">
                  <Audio :project="project"/>
              </div>
            </div>
            <router-link class="btn btn-secondary btn-xl" to="/audios">All Audio Courses</router-link>
        </div>

        <div class="course-info container">
            <h3>Popular Video Courses</h3>
            <div class="row mt-3">
              <div class="col-md-4 col-sm-6" v-for="project in projectzs" :key="project.id">
                  <Video :project="project"/>
              </div>
            </div>
            <router-link class="btn btn-secondary btn-xl" to="/videos">All Video Courses</router-link>
        </div>

        <div class="space-bottom"></div>
</template>

<script>
//import Project from "../components/Project.vue";
import image from '../../public/assets/img/now.jpg'
import Book from "../components/Book.vue";
import Video from "../components/Video.vue";
import Audio from "../components/Audio.vue";

export default{
    
    name:"Home",
    data(){
        return{
            image:image
        }
    },
    components:{
        Book,Audio,Video
    },
    computed:{
        projects(){
            return this.$store.state.projects.slice(0,6);
        },
         projectz(){
            return this.$store.state.projects.slice(0,3);
        },
         projectzs(){
            return this.$store.state.projects.slice(3,6);
        },

    }
    
}

</script>

<style scoped>
p{
  font-size: 15px;
}
.btn-secondary{
  background: #42b983;
}
.btn-xl{
  padding:15px 20px !important
}
.card1{
    background-color:#fff;
    border-radius:15px;
    height:auto;
     /*border:2px solid #919ba9;*/
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 24px 4px;
    padding:40px 15px;
    text-align: center;
    margin-bottom: 10px;
}
.card1:hover{
    box-shadow: rgb(0 0 0 / 20%) 4px 2px 24px 4px;
}

.card1:hover .course-icons{
    color: #42b983;
}
.course-icons{
  font-size: 40px;
  margin:10px;

}

header.masthead {
  padding-top: 10rem;
  padding-bottom: calc(10rem - 4.5rem);
  /*background: linear-gradient(to bottom, rgba(92, 77, 66, 0.8) 0%, rgba(92, 77, 66, 0.8) 100%), url("/assets/img/bg-masthead.jpg");*/
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
}
header.masthead h1, header.masthead .h1 {
  font-size: 2.25rem;
}
@media (min-width: 992px) {
  header.masthead {
    height: 100vh;
    min-height: 40rem;
    padding-top: 4.5rem;
    padding-bottom: 0;
  }
  header.masthead p {
    font-size: 1.15rem;
  }
  header.masthead h1, header.masthead .h1 {
    font-size: 3rem;
  }
}
@media (min-width: 1200px) {
  header.masthead h1, header.masthead .h1 {
    font-size: 3.5rem;
  }
}

.btn-xl {
  padding: 1.25rem 2.25rem;
  font-size: 0.85rem;
  font-weight: 700;
  text-transform: uppercase;
  border: none;
  border-radius: 10rem;
}

.text-white-75 {
  color: rgba(255, 255, 255, 0.75) !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.course-info{
  text-align: center;
  margin:70px 0px 30px 0px;
}
.space-bottom{
  margin-bottom:100px;
}

</style>
<template>
<div class="container main-body">
    <div class="row">
       <div class="col-sm-6 col-md-4 col-12" v-for="project in projects" :key="project.id">
           <Audio :project="project" />
       </div>
   </div>
</div>   
</template>

<script>
import Audio from "../components/Audio.vue";

export default{
    
    name:"Audios",
    data(){
        return{

        }
    },
    components:{
        Audio
    },
    computed:{
        projects(){
            return this.$store.state.projects;
        }
    }
    
}
</script>

<style>

</style>
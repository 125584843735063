<template>
<div class="container main-body">
    <div class="row mt-3">
        <div class="col-md-2 col-sm-6 col-6" v-for="project in projects" :key="project.id">
            <Book :book="project"/>
        </div>
    </div>    
</div>
</template>

<script>
import Book from "../components/Book.vue";


export default{
    
    name:"Categories",
    data(){
        return{

        }
    },
    components:{
        Book
    },
    computed:{
        categories(){
            return this.$store.state.categories;
        },
        projects(){
            return this.$store.state.projects;
        }
    }
    
}
</script>

<style scoped>
h6{
    text-decoration: none !important;
}
h6:hover{
    text-decoration: none !important;
}
</style>
<template>
   <router-link to="/project-info">
            <div class="card card-book">
                <img :src="'/assets/img/portfolio/thumbnails/js'+book.id+'.jpg'" class="book-img" />
            </div>
    </router-link>
            <!-- card.// --> 
</template>

<script>
   export default{
      props:{
          book: Object
      }
   }
</script>

<style scoped>
.card-book{
    margin-bottom: 10px;
     border-radius: 20px !important;
   
}

.book-img{
    border-radius: 20px;
}
</style>
<template>
    <div class="container main-body">
       <div class="row">
          
           <div class="content-section col-sm-12 col-md-8 col-lg-8">
                
                <div class="content-bottom">
                    <!--h4 class="text-center m-2">{{ course.title }}</h4-->
                    <!-- Start DEMO HTML (Use the following code into your project)-->
                    <div class="simple-audio-player" id="simp" data-config='{"shide_top":false,"shide_btm":false,"auto_load":true}'>
                        <!--img :src="'/assets/img/portfolio/thumbnails/'+course.thumb" alt=""/-->
                        <h4 class="text-center m-2">{{ course.title }}</h4>
                    <div class="simp-playlist">
                        <ul>
                            <li v-for="course in course.data"><span class="simp-source" :data-src="video_path+'/'+course.video" :data-cover="'/assets/img/portfolio/thumbnails/'+course.cover">{{ course.label }}</span><span class="simp-desc">{{ course.title }}</span></li>
                        </ul>
                    </div>
                    <div class="simp-footer">Enjoy Listening and watching</div>
                    </div>    
     <!-- END EDMO HTML (Happy Coding!)-->

                    <!--div class="play-list">
                        <button @click="alert('hellow')" class="play-icon">
                            <i class="fa fa-play"></i>
                        </button>
                        <div class="item-info">
                            <div class="labels">
                                <p class="label-title">Episode 1</p>
                                <small class="label-time">5.67 min</small>
                            </div>
                            <p class="item-title">title goin here man</p>
                        </div>
                    </div-->

                    <div class="mt-5 border-left border-right">
                          <div class="d-flex justify-content-between pt-3 pb-2">
                              <input type="text" name="text" placeholder="+ Add a Comment" class="form-control addtxt"/>
                              <button class="btn btn-secondary"><i class="fa fa-send"></i></button> 
                            </div>
                          <div class="d-flex justify-content-center py-2 comment">
                              <div class="second py-2 px-2"> <span class="text1">Type your note, and hit enter to add it</span>
                                  <div class="d-flex justify-content-between py-1 pt-2">
                                      <div><img src="https://i.imgur.com/AgAC1Is.jpg"><span class="text2">Martha</span></div>
                                      <div><span class="text3">Like</span><span class="thumbup"><i class="fa fa-thumbs-o-up"></i></span><span class="text4">3</span></div>
                                  </div>
                              </div>
                          </div>
                          <div class="d-flex justify-content-center py-2 comment">
                              <div class="second py-2 px-2"> <span class="text1">Type your note, and hit enter to add it</span>
                                  <div class="d-flex justify-content-between py-1 pt-2">
                                      <div><img src="https://i.imgur.com/tPvlEdq.jpg" ><span class="text2">Curtis</span></div>
                                      <div><span class="text3">Like</span><span class="thumbup"><i class="fa fa-thumbs-o-up"></i></span><span class="text4">3</span></div>
                                  </div>
                              </div>
                          </div>
                          <div class="d-flex justify-content-center py-2 comment">
                              <div class="second py-2 px-2"> <span class="text1">Type your note, and hit enter to add it</span>
                                  <div class="d-flex justify-content-between py-1 pt-2">
                                      <div><img src="https://i.imgur.com/gishFbz.png" ><span class="text2">Beth</span></div>
                                      <div><span class="text3 text3o">Liked</span><span class="thumbup"><i class="fa fa-thumbs-up thumbupo"></i></span><span class="text4 text4i">1</span></div>
                                  </div>
                              </div>
                          </div>
                          <div class="d-flex justify-content-center py-2 comment">
                              <div class="second py-2 px-2"> <span class="text1">Type your note, and hit enter to add it</span>
                                  <div class="d-flex justify-content-between py-1 pt-2">
                                      <div><img src="https://i.imgur.com/tPvlEdq.jpg"><span class="text2">Curtis</span></div>
                                      <div><span class="text3">Like</span><span class="thumbup"><i class="fa fa-thumbs-o-up"></i></span><span class="text4 text4o">1</span></div>
                                  </div>
                              </div>
                          </div>
                      </div>

                </div>
           </div>
           <div class="content-section col-sm-8 col-md-4 col-lg-4">
              <h4>You May Like</h4>
              <Video v-for="project in projects" :key="project.id" :project="project" />
           </div>
       </div>
    </div>    
</template>

<script>
import Video from "../components/Video.vue";

export default {
    data() {
        
    },
    components:{
       Video 
    },
    computed:{
        projects(){
            return this.$store.state.projects.slice(0,4);
        },
        course(){
            return this.$store.state.course;
        },
        video_path(){
          return this.$store.state.video_url;
        }
    },
    mounted(){

        /*
Example: https://www.codehim.com/demo/javascript-audio-player-with-playlist/
*/
// Multiple events to a listener
function addEventListener_multi(element, eventNames, handler) {
  var events = eventNames.split(' ');
  events.forEach(e => element.addEventListener(e, handler, false));
}

// Random numbers in a specific range
function getRandom(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

// Position element inside element
function getRelativePos(elm) {
  var pPos = elm.parentNode.getBoundingClientRect(); // parent pos
  var cPos = elm.getBoundingClientRect(); // target pos
  var pos = {};

  pos.top    = cPos.top    - pPos.top + elm.parentNode.scrollTop,
  pos.right  = cPos.right  - pPos.right,
  pos.bottom = cPos.bottom - pPos.bottom,
  pos.left   = cPos.left   - pPos.left;

  return pos;
}

function formatTime(val) {
  var h = 0, m = 0, s;
  val = parseInt(val, 10);
  if (val > 60 * 60) {
   h = parseInt(val / (60 * 60), 10);
   val -= h * 60 * 60;
  }
  if (val > 60) {
   m = parseInt(val / 60, 10);
   val -= m * 60;
  }
  s = val;
  val = (h > 0)? h + ':' : '';
  val += (m > 0)? ((m < 10 && h > 0)? '0' : '') + m + ':' : '0:';
  val += ((s < 10)? '0' : '') + s;
  return val;
}

function simp_initTime() {
  simp_controls.querySelector('.start-time').innerHTML = formatTime(simp_audio.currentTime); //calculate current value time
  if (!simp_isStream) {
    simp_controls.querySelector('.end-time').innerHTML = formatTime(simp_audio.duration); //calculate total value time
    simp_progress.value = simp_audio.currentTime / simp_audio.duration * 100; //progress bar
  }
  
  // ended of the audio
  if (simp_audio.currentTime == simp_audio.duration) {
    simp_controls.querySelector('.simp-plause').classList.remove('fa-pause');
    simp_controls.querySelector('.simp-plause').classList.add('fa-play');
    simp_audio.removeEventListener('timeupdate', simp_initTime);
    
    if (simp_isNext) { //auto load next audio
      var elem;
      simp_a_index++;
      if (simp_a_index == simp_a_url.length) { //repeat all audio
        simp_a_index = 0;
        elem = simp_a_url[0];
      } else {
        elem = simp_a_url[simp_a_index];  
      }
      simp_changeAudio(elem);
      simp_setAlbum(simp_a_index);
    } else {
      simp_isPlaying = false;
    }
  }
}

function simp_initAudio() {
  // if readyState more than 2, audio file has loaded
  simp_isLoaded = simp_audio.readyState == 4 ? true : false;
  simp_isStream = simp_audio.duration == 'Infinity' ? true : false;
  simp_controls.querySelector('.simp-plause').disabled = false;
  simp_progress.disabled = simp_isStream ? true : false;
  if (!simp_isStream) {
    simp_progress.parentNode.classList.remove('simp-load','simp-loading');
    simp_controls.querySelector('.end-time').innerHTML = formatTime(simp_audio.duration);
  }
  simp_audio.addEventListener('timeupdate', simp_initTime); //tracking load progress
  if (simp_isLoaded && simp_isPlaying) simp_audio.play();
  
  // progress bar click event
  addEventListener_multi(simp_progress, 'touchstart mousedown', function(e) {
    if (simp_isStream) {
      e.stopPropagation();
      return false;
    }
    if (simp_audio.readyState == 4) {
      simp_audio.removeEventListener('timeupdate', simp_initTime);
      simp_audio.pause();
    }
  });
  
  addEventListener_multi(simp_progress, 'touchend mouseup', function(e) {
    if (simp_isStream) {
      e.stopPropagation();
      return false;
    }
    if (simp_audio.readyState == 4) {
      simp_audio.currentTime = simp_progress.value * simp_audio.duration / 100;
      simp_audio.addEventListener('timeupdate', simp_initTime);
      if (simp_isPlaying) simp_audio.play();
    }
  });
}

function simp_loadAudio(elem) {
  simp_progress.parentNode.classList.add('simp-loading');
  simp_controls.querySelector('.simp-plause').disabled = true;
  simp_audio.querySelector('source').src = elem.dataset.src;
  simp_audio.load();
  
  simp_audio.volume = parseFloat(simp_v_num / 100); //based on valume input value
  simp_audio.addEventListener('canplaythrough', simp_initAudio); //play audio without stop for buffering
  
  // if audio fails to load, only IE/Edge 9.0 or above
  simp_audio.addEventListener('error', function() {
    alert('Please reload the page.');
  });
}

function simp_setAlbum(index) {
  simp_cover.innerHTML = simp_a_url[index].dataset.cover ? '<div style="background:url(' + simp_a_url[index].dataset.cover + ') no-repeat;background-size:cover;width:80px;height:80px;"></div>' : '<i class="fa fa-music fa-5x"></i>';
  simp_title.innerHTML = simp_source[index].querySelector('.simp-source').innerHTML;
  simp_artist.innerHTML = simp_source[index].querySelector('.simp-desc') ? simp_source[index].querySelector('.simp-desc').innerHTML : '';
}

function simp_changeAudio(elem) {
	simp_isLoaded = false;
  simp_controls.querySelector('.simp-prev').disabled = simp_a_index == 0 ? true : false;
  simp_controls.querySelector('.simp-plause').disabled = simp_auto_load ? true : false;
  simp_controls.querySelector('.simp-next').disabled = simp_a_index == simp_a_url.length-1 ? true : false;
  simp_progress.parentNode.classList.add('simp-load');
  simp_progress.disabled = true;
  simp_progress.value = 0;
  simp_controls.querySelector('.start-time').innerHTML = '00:00';
  simp_controls.querySelector('.end-time').innerHTML = '00:00';
  elem = simp_isRandom && simp_isNext ? simp_a_url[getRandom(0, simp_a_url.length-1)] : elem;
  
  // playlist, audio is running
  for (var i = 0; i < simp_a_url.length; i++) {
    simp_a_url[i].parentNode.classList.remove('simp-active');
    if (simp_a_url[i] == elem) {
      simp_a_index = i;
      simp_a_url[i].parentNode.classList.add('simp-active');
    }
  }
  
  // scrolling to element inside element
  var simp_active = getRelativePos(simp_source[simp_a_index]);
  simp_source[simp_a_index].parentNode.scrollTop = simp_active.top;
  
  if (simp_auto_load || simp_isPlaying) simp_loadAudio(elem);
  
  if (simp_isPlaying) {
    simp_controls.querySelector('.simp-plause').classList.remove('fa-play');
    simp_controls.querySelector('.simp-plause').classList.add('fa-pause');
  }
}

function simp_startScript() {
  ap_simp = document.querySelector('#simp');
  simp_audio = ap_simp.querySelector('#audio');
  simp_album = ap_simp.querySelector('.simp-album');
  simp_cover = simp_album.querySelector('.simp-cover');
  simp_title = simp_album.querySelector('.simp-title');
  simp_artist = simp_album.querySelector('.simp-artist');
  simp_controls = ap_simp.querySelector('.simp-controls');
  simp_progress = simp_controls.querySelector('.simp-progress');
  simp_volume = simp_controls.querySelector('.simp-volume');
  simp_v_slider = simp_volume.querySelector('.simp-v-slider');
  simp_v_num = simp_v_slider.value; //default volume
  simp_others = simp_controls.querySelector('.simp-others');
  simp_auto_load = simp_config.auto_load; //auto load audio file
  
  if (simp_config.shide_top) simp_album.parentNode.classList.toggle('simp-hide');
  if (simp_config.shide_btm) {
    simp_playlist.classList.add('simp-display');
    simp_playlist.classList.toggle('simp-hide');
  }
  
  if (simp_a_url.length <= 1) {
    simp_controls.querySelector('.simp-prev').style.display = 'none';
    simp_controls.querySelector('.simp-next').style.display = 'none';
    simp_others.querySelector('.simp-plext').style.display = 'none';
    simp_others.querySelector('.simp-random').style.display = 'none';
  }

  // Playlist listeners
  simp_source.forEach(function(item, index) {
    if (item.classList.contains('simp-active')) simp_a_index = index; //playlist contains '.simp-active'
    item.addEventListener('click', function() {
      simp_audio.removeEventListener('timeupdate', simp_initTime);
      simp_a_index = index;
      simp_changeAudio(this.querySelector('.simp-source'));
      simp_setAlbum(simp_a_index);
    });
  });
  
  // FIRST AUDIO LOAD =======
  simp_changeAudio(simp_a_url[simp_a_index]);
  simp_setAlbum(simp_a_index);
  // FIRST AUDIO LOAD =======
  
  // Controls listeners
  simp_controls.querySelector('.simp-plauseward').addEventListener('click', function(e) {
    var eles = e.target.classList;
    if (eles.contains('simp-plause')) {
      if (simp_audio.paused) {
        if (!simp_isLoaded) simp_loadAudio(simp_a_url[simp_a_index]);
        simp_audio.play();
        simp_isPlaying = true;
        eles.remove('fa-play');
        eles.add('fa-pause');
      } else {
        simp_audio.pause();
        simp_isPlaying = false;
        eles.remove('fa-pause');
        eles.add('fa-play');
      }
    } else {
      if (eles.contains('simp-prev') && simp_a_index != 0) {
        simp_a_index = simp_a_index-1;
        e.target.disabled = simp_a_index == 0 ? true : false;
      } else if (eles.contains('simp-next') && simp_a_index != simp_a_url.length-1) {
        simp_a_index = simp_a_index+1;
        e.target.disabled = simp_a_index == simp_a_url.length-1 ? true : false;
      }
      simp_audio.removeEventListener('timeupdate', simp_initTime);
      simp_changeAudio(simp_a_url[simp_a_index]);
      simp_setAlbum(simp_a_index);
    }
  });
  
  // Audio volume
  simp_volume.addEventListener('click', function(e) {
    var eles = e.target.classList;
    if (eles.contains('simp-mute')) {
      if (eles.contains('fa-volume-up')) {
        eles.remove('fa-volume-up');
        eles.add('fa-volume-off');
        simp_v_slider.value = 0;
      } else {
        eles.remove('fa-volume-off');
        eles.add('fa-volume-up');
        simp_v_slider.value = simp_v_num;
      }
    } else {
      simp_v_num = simp_v_slider.value;
      if (simp_v_num != 0) {
        simp_controls.querySelector('.simp-mute').classList.remove('fa-volume-off');
        simp_controls.querySelector('.simp-mute').classList.add('fa-volume-up');
      }
    }
    simp_audio.volume = parseFloat(simp_v_slider.value / 100);
  });
  
  // Others
  simp_others.addEventListener('click', function(e) {
    var eles = e.target.classList;
    if (eles.contains('simp-plext')) {
      simp_isNext = simp_isNext && !simp_isRandom ? false : true;
      if (!simp_isRandom) simp_isRanext = simp_isRanext ? false : true;
      eles.contains('simp-active') && !simp_isRandom ? eles.remove('simp-active') : eles.add('simp-active');
    } else if (eles.contains('simp-random')) {
      simp_isRandom = simp_isRandom ? false : true;
      if (simp_isNext && !simp_isRanext) {
        simp_isNext = false;
        simp_others.querySelector('.simp-plext').classList.remove('simp-active');
      } else {
        simp_isNext = true;
        simp_others.querySelector('.simp-plext').classList.add('simp-active');
      }
      eles.contains('simp-active') ? eles.remove('simp-active') : eles.add('simp-active');
    } else if (eles.contains('simp-shide-top')) {
      simp_album.parentNode.classList.toggle('simp-hide');
    } else if (eles.contains('simp-shide-bottom')) {
      simp_playlist.classList.add('simp-display');
      simp_playlist.classList.toggle('simp-hide');
    }
  });
}

// Start simple player
if (document.querySelector('#simp')) {
  var simp_auto_load, simp_audio, simp_album, simp_cover, simp_title, simp_artist, simp_controls, simp_progress, simp_volume, simp_v_slider, simp_v_num, simp_others;
  var ap_simp = document.querySelector('#simp');
  var simp_playlist = ap_simp.querySelector('.simp-playlist');
  var simp_source = simp_playlist.querySelectorAll('li');
  var simp_a_url = simp_playlist.querySelectorAll('[data-src]');
  var simp_a_index = 0;
  var simp_isPlaying = false;
  var simp_isNext = false; //auto play
  var simp_isRandom = false; //play random
  var simp_isRanext = false; //check if before random starts, simp_isNext value is true
  var simp_isStream = false; //radio streaming
  var simp_isLoaded = false; //audio file has loaded
  var simp_config = ap_simp.dataset.config ? JSON.parse(ap_simp.dataset.config) : {
    shide_top: false, //show/hide album
    shide_btm: false, //show/hide playlist
    auto_load: false //auto load audio file
  };
  
  var simp_elem = '';
  simp_elem += '<video id="audio" style="width: 100%;" preload><source src="" type="video/mp4"></video>';
  simp_elem += '<div class="simp-display"><div class="simp-album w-full flex-wrap"><div class="simp-cover"><i class="fa fa-music fa-5x"></i></div><div class="simp-info"><div class="simp-title">Title</div><div class="simp-artist">Artist</div></div></div></div>';
  simp_elem += '<div class="simp-controls flex-wrap flex-align">';
  simp_elem += '<div class="simp-plauseward flex flex-align"><button type="button" class="simp-prev fa fa-backward" disabled></button><button type="button" class="simp-plause fa fa-play" disabled></button><button type="button" class="simp-next fa fa-forward" disabled></button></div>';
  simp_elem += '<div class="simp-tracker simp-load"><input class="simp-progress" type="range" min="0" max="100" value="0" disabled/><div class="simp-buffer"></div></div>';
  simp_elem += '<div class="simp-time flex flex-align"><span class="start-time">00:00</span><span class="simp-slash">&#160;/&#160;</span><span class="end-time">00:00</span></div>';
  simp_elem += '<div class="simp-volume flex flex-align"><button type="button" class="simp-mute fa fa-volume-up"></button><input class="simp-v-slider" type="range" min="0" max="100" value="100"/></div>';
  simp_elem += '<div class="simp-others flex flex-align"><button type="button" class="simp-plext fa fa-play-circle" title="Auto Play"></button><button type="button" class="simp-random fa fa-random" title="Random"></button><div class="simp-shide"><button type="button" class="simp-shide-top fa fa-caret-up" title="Show/Hide Album"></button><button type="button" class="simp-shide-bottom fa fa-caret-down" title="Show/Hide Playlist"></button></div></div>';
  simp_elem += '</div>'; //simp-controls
  
  var simp_player = document.createElement('div');
  simp_player.classList.add('simp-player');
  simp_player.innerHTML = simp_elem;
  ap_simp.insertBefore(simp_player, simp_playlist);
  simp_startScript();
}

    }
}


</script>

<style scoped>

.content-section{
    background-color:#fff;
    border-radius:22px;
    height:auto;
    /*border:2px solid #919ba9;*/
    box-shadow: rgb(0 0 0 / 20%) 4px 2px 24px 4px; 
    padding:20px;
}
.content-bottom{
    justify-content: left !important;
    margin:10px 0;
}
.play-list{
    display:flex;
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 24px 0px;
    padding: 10px;
    border-radius: 15px;
}
.play-icon{
    width: 50px;
    height: 50px;
    background-color: #fff;
    box-shadow: rgb(0 0 0 / 30%) 0px 0px 24px 0px;
    border-radius: 50%;
    color:#42b983;
    display: flex;
    align-items: center;
    margin-right: 10px;
    font-size:20px;
}
.play-list:hover .play-icon{
    color:#fff;
    background-color: #42b983;
}
.play-icon .fa-play{
    margin-left:20px;
}
.labels{
    display: flex;
    justify-content: space-between;
    width:100%;
}

.item-info{
   width:90%;
}

.item-title{
    font-size: 17px;
    font-weight: 400;
}
.label-title{
    font-size: 17px;
    font-weight: 600; 
}
button{
    border: 0px !important;
}
video{
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
}

/**comments starts hereeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee */
img{
  width: 32px;
  border-radius: 50%;
}
.comment{
  width:100%;
}
.btn-secondary{
  background-color:#42b983;
}
.addtxt{
	padding-top: 15px;
	padding-bottom: 15px;
	font-size: 13px;
	width: 100%;
	font-weight: 500;
  border-top:0px;
  border-bottom:2px #333 solid;
}

.form-control:focus{
	color: #000;
  border-top:0px;
}
.second{
	width: 100%;
	background-color: white;
	border-radius: 4px;
	box-shadow: 10px 10px 5px #aaaaaa;
}
.text1{
	font-size: 13px;
    font-weight: 500;
    color: #56575b;
}
.text2{
	font-size: 13px;
    font-weight: 500;
    margin-left: 6px;
    color: #56575b;
}
.text3{
	font-size: 13px;
    font-weight: 500;
    margin-right: 4px;
    color: #828386;
}
.text3o{
	color: #42b983;

}
.text4{
	font-size: 13px;
    font-weight: 500;
    color: #828386;
}
.text4i{
	color: #42b983;
}
.text4o{
	color: white;
}
.thumbup{
	font-size: 13px;
    font-weight: 500;
    margin-right: 5px;
}
.thumbupo{
	color: #42b983;
}

/*here endssssssssssssssssssssssssssssssssssssssssssssss*/

@media only screen and (max-width: 767px) {
    .content-section{
    border-radius:8px;
    /*border:2px solid #919ba9;*/
    box-shadow: rgb(0 0 0 / 20%) 4px 2px 24px 4px; 
    padding:8px;
    margin:8px;
}
.item-title{
    font-size: 14px;
    font-weight: 400;
}
.label-title{
    font-size: 14px;
    font-weight: 600; 
}
.item-info{
   width:80%;
}

}
</style>

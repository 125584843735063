<template>
    <div class="meeting-item">
        <div class="thumb">
            <div class="price">
            <span>Tsh {{ project.price }}</span>
            </div>
            <router-link to="/video-info"><img :src="'/assets/img/portfolio/thumbnails/'+project.image" alt=""></router-link>
        </div>
        <div class="down-content">
            <router-link to="/video-info"><h4>{{ project.name }}</h4></router-link>

            <div class="d-flex">
                <small><img src="/assets/img/perfil.png" alt="" class="user__img"/> Richard Mlimila</small>
            </div>
        </div>
        
    </div>
</template>

<script>
   export default{
      props:{
          project:Object
      }
   }
</script>

<style scoped>
.meeting-item .down-content {
    padding:10px;
}

.meeting-item{
    background-color:#fff;
    border-radius:22px;
    height:auto;
    /*border:2px solid #919ba9;*/
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 24px 4px;
    padding:10px;
}
.meeting-item:hover{
   box-shadow: rgb(0 0 0 / 20%) 4px 2px 24px 4px; 
}
.user__img {
  width: 32px;
  border-radius: 50%;
}
</style>

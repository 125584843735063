import { createRouter, createWebHistory } from 'vue-router'

import Home from '../views/Home.vue'
import Profile from '../views/Profile.vue'
import Contact from '../views/Contact.vue'
import Audio from '../views/Audios.vue'
import Books from '../views/Books.vue'
import Videos from '../views/Videos.vue'
import ProjectInfo from '../views/ProjectInfo.vue'
import AudioPage from '../views/AudioPage.vue'
import VideoPage from '../views/VideoPage.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/audios',
    name: 'audios',
    component: Audio
  },
  {
    path: '/audios-course',
    name: 'audios-course',
    component: AudioPage
  }
  ,
  {
    path: '/video-info',
    name: 'video-course',
    component: VideoPage
  }
  ,
  {
    path: '/videos',
    name: 'videos',
    component: Videos
  }
  ,
  {
    path: '/books',
    name: 'books',
    component: Books
  }
  ,
  {
    path: '/contacts',
    name: 'conatct',
    component: Contact
  }
  ,
  {
    path: '/profile',
    name: 'profile',
    component: Profile
  }
  ,
  {
    path: '/project-info',
    name: 'projectinfo',
    component: ProjectInfo
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router

<template>

    <div class="card1  mt-3 p-3">
        <div class="d-flex">
            <small><img src="/assets/img/perfil.png" alt="" class="user__img"/> Richard Mlimila</small>
        </div>
        <div class="mt-3">
            <h5 class="text1">{{ project.name }}</h5>
        </div>
        <div class="mt-3 d-flex justify-content-between p-2">
            <span class="text-price">Tsh {{ project.price }}</span>
            <router-link to="/audios-course" class="btn btn-submit"><i class="fa fa-play mr-1"></i> Play Now</router-link>
        </div>
    </div>
    
</template>

<script>
   export default{
      props:{
          project:Object
      }
   }
</script>

<style scoped>
.card1{
    background-color:#fff;
    border-radius:15px;
    height:auto;
     /*border:2px solid #919ba9;*/
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 24px 4px;
    padding:10px;
}
.card1:hover .btn-submit{
    background-color: #42b983;
}
.card1:hover{
    box-shadow: rgb(0 0 0 / 20%) 4px 2px 24px 4px;
}
.first {
    color:#a4adb8;
    background-color: #f1884d;
    width:100%;
}
.text1{
    color:#142948;
    font-weight:700;
}
.btn-submit{
    border-radius:50px;
    border:none;
    height:35px;
    width:120px;
    font-size:14px;
    font-weight:500;
    color:#fff;
    background-color: #142948;

}
.text2{
    color:#000;
    font-weight:700;
}

.text-price{
    color:#42b983;
    font-weight: 700;
}

.user__img {
  width: 32px;
  border-radius: 50%;
}
</style>